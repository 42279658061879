import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";
import donationStore from "../../store/DonationStore";
import { WordCarousel } from "../../utils/wordCarusel";
import axios from "axios";
import { url } from "../../settings/seting";
import { FeatherIcon } from "../../utils/featherIcon";

const SectionOne = observer(() => {
  useEffect(() => {
    axios
      .get(`${url}donations/all-project?limit=6&offset=0`)
      .then((data) => {donationStore.setDonationAll(data.data.rows); donationStore.setTotal(data.data.total)});
  }, []);

  return (
    <>
      <section className="bg-tranquil position-relative overlap-height">
        <div
          className="position-absolute left-minus-200px top-25"
          data-bottom-top="transform: translateY(-80px)"
          data-top-bottom="transform: translateY(80px)"
        >
          <img src="images/demo-elearning-bg-04.png" alt="" />
        </div>
        <div className="container overlap-gap-section">
          <div className="row align-items-center mb-4">
            <div className="col-xl-5 lg-mb-30px text-center text-xl-start">
              <h2 className="alt-font text-dark-gray fw-600 ls-minus-3px mb-0">
                Popular donation
              </h2>
            </div>
            <div className="col-xl-7 tab-style-04 text-center text-xl-end">
              <ul className="portfolio-filter fw-500 nav nav-tabs justify-content-center justify-content-xl-end border-0">
                <li className="nav active">
                  <a data-filter="*" href="#">
                    All
                  </a>
                </li>
                <li className="nav">
                  <a data-filter=".development" href="#">
                    Development
                  </a>
                </li>
                <li className="nav">
                  <a data-filter=".business" href="#">
                    Business
                  </a>
                </li>
                <li className="nav">
                  <a data-filter=".design" href="#">
                    Design
                  </a>
                </li>
                <li className="nav">
                  <a data-filter=".marketing" href="#">
                    Marketing
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 filter-content p-md-0">
              <ul className="portfolio-wrapper  custom-grid gutter-extra-large">
                {donationStore.all.map((item, index) => (
                  <li
                    key={index}
                    className="grid-item design transition-inner-all"
                  >
                    <div className="services-box-style-06 border-radius-6px hover-box overflow-hidden box-shadow-large">
                      <div className="image">
                        <a href="demo-elearning-courses-details.html">
                          <img
                            src="https://via.placeholder.com/600x385"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="bg-white position-relative">
                        <div className="bg-dark-gray w-80px h-80px md-w-75px md-h-75px rounded-circle d-flex justify-content-center align-items-center fw-500 text-white fs-20 position-absolute right-30px top-minus-40px">
                          $55
                        </div>
                        <div className="ps-35px pe-35px pt-30px pb-30px border-bottom border-color-transparent-dark-very-light xl-ps-25px xl-pe-25px">
                          <span className="d-block mb-10px">
                            <a
                              href="demo-elearning-courses.html"
                              className="text-dark-gray text-uppercase fs-15 fw-600 services-text"
                            >
                              Design
                            </a>
                            <span className="fs-16">Matthew taylor</span>
                          </span>
                          <div className="d-flex align-items-center mb-5px">
                            <a
                              href="demo-elearning-courses-details.html"
                              className="text-dark-gray fw-600 fs-19 md-fs-18 lh-28 w-90"
                            >
                              Business accounting and taxation fundamental
                            </a>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="d-inline-block me-auto">
                              <div className="review-star-icon float-start">
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                              </div>
                              <div className="fs-15 float-start ms-5px position-relative top-2px">
                                (20 Reviews)
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center ps-35px pe-35px xl-ps-25px xl-pe-25px pt-15px pb-20px">
                          <div className="me-auto">
                            <i className="feather icon-feather-clipboard text-dark-gray d-inline-block me-5px"></i>
                            <span className="fs-16 text-dark-gray fw-500">
                              10 Lessons
                            </span>
                          </div>
                          <div>
                            <i className="feather icon-feather-users text-dark-gray d-inline-block me-5px"></i>
                            <span className="fs-16 text-dark-gray fw-500">
                              18 Students
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center">
              <ul className="pagination pagination-style-01 fs-13 mb-0 fw-500">
                <li className="page-item">
                  <a className="page-link" href="#">
                    <FeatherIcon name="arrow-left" size="24" />
                  </a>
                </li>



                {donationStore.total}
                <li className="page-item">
                  <a className="page-link" href="#">
                    01
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="#">
                    02
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    03
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    04
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    <FeatherIcon name="arrow-right" size="24" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default SectionOne;
