import axios from "axios";
import { url } from "../settings/seting";

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken) {
    console.warn("Нет refresh token, требуется вход в систему.");
    return null;
  }

  try {
    const response = await axios.post(`${url}auth/refresh-token`, {
      refreshToken,
    });

    const { accessToken, refreshToken: newRefreshToken } = response.data;

    localStorage.setItem("token", accessToken);
    localStorage.setItem("refreshToken", newRefreshToken);

    return accessToken;
  } catch (err) {
    console.error("Ошибка при обновлении токена", err);
    throw new Error("Ошибка при обновлении токена");
  }
};

export const checkAuth = async () => {
  let token = localStorage.getItem("token");

  if (!token) {
    console.warn("Токен отсутствует, требуется вход.");
    return false;
  }

  try {
    const response = await axios.get(`${url}auth/verify-token`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.valid;
  } catch (err) {
    if (err.response?.status === 401) {
      console.warn("Токен истек, пытаемся обновить...");
      token = await refreshAccessToken();
      if (!token) {
        console.error("Не удалось обновить токен.");
        window.location.href = "/login";
        return false;
      }

      const retryResponse = await axios.get(`${url}auth/verify-token`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return retryResponse.data.valid;
    } else {
      return false;
    }
  }
};
