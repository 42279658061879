// HeaderStore.js
import { makeAutoObservable } from "mobx";

class DonationStore {
  donation = {};
  all = [];
  total = null;
  limit = null;
  offset = null;

  constructor() {
    makeAutoObservable(this);
  }

  setDonationOne(data) {
    this.donation = data;
  }
  setDonationAll(data) {
    this.all = data;
  }
  setTotal(data) {
    this.total = data;
  }
}

const donationStore = new DonationStore();
export default donationStore;
