import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { checkAuth } from "../utils/auth";
import { observer } from "mobx-react";
import authStore from "../store/AuthStore";

const PrivateRoute = observer(({ children }) => {
  useEffect(() => {
    const verifyAuth = async () => {
      const authStatus = await checkAuth();
      authStore.setIsAuthenticated(authStatus);
    };

    verifyAuth();
  }, []);

  if (authStore.isAuthenticated === false) {
    return <p>Loading...</p>;
  }

  return authStore.isAuthenticated ? children : <Navigate to="/login" />;
});

export default PrivateRoute;
