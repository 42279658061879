import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { motion } from "framer-motion";
import { FeatherIcon } from "../utils/featherIcon";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="bg-gradient-aztec-green position-relative">
        <div className="position-absolute left-minus-100px top-25px">
          <img src="images/demo-elearning-bg-05.png" alt="" className="w-80" />
        </div>
        <div
          className="background-position-center-top h-100 w-100 position-absolute left-0px top-0"
          style={{
            backgroundImage: "url('images/vertical-line-bg-small.svg')",
          }}
        ></div>
        <div className="container overlap-section">
          <div
            className="row g-0 justify-content-center align-items-center text-center bg-base-color border-radius-6px ps-5 pe-5 pt-3 pb-3 mb-8 sm-p-25px background-position-left-bottom background-no-repeat contain-background position-relative"
            style={{
              backgroundImage: "url('images/demo-elearning-bg-06.png')",
            }}
          >
            <div className="col-lg-auto lg-mb-20px">
              <h6 className="alt-font fw-600 text-dark-gray m-0 ls-minus-1px d-inline-block me-30px lg-me-0">
                Admission is open for the next year batch
              </h6>
            </div>

            <div className="col-auto">
            <Link
               to="demo-elearning-contact.html"
                className="btn btn-extra-large btn-dark-gray btn-rounded btn-box-shadow btn-switch-text d-inline-block me-20px align-middle xs-m-0"
              >
                <span
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignContent: "center",
                  }}
                >
                  <span className="btn-double-text" data-text="Get started now">
                    Get started now
                  </span>
                  <span>
                    <FeatherIcon name="thumbs-up" size="24" color={`white`} />
                    {/* <i className="feather icon-feather-thumbs-up"></i> */}
                  </span>
                </span>
              </Link>
              <span className="d-block d-sm-inline-block text-dark-gray fs-19 fw-600 left-icon d-inline-block align-middle xs-mt-10px ls-minus-05px">
                <Link
                 to="tel:12345678910"
                  style={{ display: "flex", gap: "7px" }}
                >
                  <FeatherIcon name="phone-call" size="24" color={`#313e3b`} />
                  {/* <i className="feather icon-feather-phone-call"></i> */}
                  +1 234 567 8910
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="container footer-dark text-center text-sm-start position-relative">
          <div className="row mb-5 sm-mb-7 xs-mb-30px">
            <div className="col-lg-3 col-md-4 col-sm-6 d-flex flex-column last-paragraph-no-margin md-mb-40px xs-mb-30px">
              <Link
               to="demo-elearning.html"
                className="footer-logo mb-15px d-inline-block"
              >
                <img
                  src="images/demo-elearning-footer-logo.png"
                  data-at2x="images/demo-elearning-footer-logo@2x.png"
                  alt=""
                />
              </Link>
              <p className="lh-28">
                We are providing high-quality courses for about ten years.
              </p>
              <div className="elements-social social-text-style-01 mt-9 xs-mt-15px">
                <ul className="small-icon light fw-500">
                  <li>
                    <Link
                      className="facebook"
                     to="https://www.facebook.com/"
                      target="_blank"
                    >
                      Fb.
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="instagram"
                     to="http://www.instagram.com"
                      target="_blank"
                    >
                      Ig.
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="twitter"
                     to="http://www.twitter.com"
                      target="_blank"
                    >
                      Tw.
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="behance"
                     to="http://www.behance.com/"
                      target="_blank"
                    >
                      Be.
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 ps-4 last-paragraph-no-margin md-mb-40px xs-mb-30px">
              <span className="fw-500 fs-18 d-block text-white mb-10px">
                Popular courses
              </span>
              <ul>
                <li>
                  <Link to="demo-elearning-courses.html">Business finance</Link>
                </li>
                <li>
                  <Link to="demo-elearning-courses.html">Advanced design</Link>
                </li>
                <li>
                  <Link to="demo-elearning-courses.html">Web development</Link>
                </li>
                <li>
                  <Link to="demo-elearning-courses.html">Data visualization</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 last-paragraph-no-margin xs-mb-30px">
              <span className="fw-500 fs-18 d-block text-white mb-10px">
                Need help?
              </span>
              <span className="lh-26 d-block">Call us directly?</span>
              <span className="text-white d-block mb-10px">
                <Link to="tel:12345678910">+1 234 567 8910 </Link>
                <span className="bg-base-color fw-700 text-dark-gray lh-22 text-uppercase border-radius-30px ps-10px pe-10px fs-11 ms-5px d-inline-block align-middle">
                  Free
                </span>
              </span>
              <span className="lh-26 d-block">Need support?</span>
              <Link
               to="mailto:help@domain.com"
                className="text-white text-decoration-line-bottom"
              >
                help@domain.com
              </Link>
            </div>

            <div className="col-lg-3 col-md-12 col-sm-6 text-md-center text-lg-start">
              <span className="fs-18 fw-500 d-block text-white mb-20px">
                Subscribe our newsletter
              </span>
              <div className="d-inline-block w-100 newsletter-style-02 position-relative mb-15px">
                <form
                  action="email-templates/subscribe-newsletter.php"
                  method="post"
                  className="position-relative w-100"
                >
                  <input
                    className="input-small bg-dark-gray border-color-transparent-white-light w-100 fs-14 form-control required"
                    type="email"
                    name="email"
                    placeholder="Enter your email..."
                  />
                  <input type="hidden" name="redirect" value="" />
                  <button
                    type="submit"
                    aria-label="submit"
                    className="btn pe-20px text-white  fs-13 fw-500 lg-ps-15px lg-pe-15px submit"
                    style={{ display: "flex", gap: "5px" }}
                  >
                    Submit{" "}
                    <FeatherIcon name="arrow-right" size="24" color={`white`} />
                    {/* <i className="feather icon-feather-arrow-right submit"></i> */}
                  </button>
                  <div className="form-results border-radius-4px pt-5px pb-5px ps-15px pe-15px fs-14 lh-22 mt-10px w-100 text-center position-absolute d-none"></div>
                </form>
              </div>
              <div className="d-flex align-items-center justify-content-center justify-content-md-center justify-content-sm-start justify-content-lg-start fs-14">
                <div className="d-inline-block">
                  <i className="line-icon-Handshake me-10px align-middle icon-very-medium"></i>
                  Protecting your privacy
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center footer-bottom border-top border-color-transparent-white-light pt-30px g-0">
            <div className="col-xl-7 ps-0 text-center text-xl-start lg-mb-10px">
              <ul className="footer-navbar fs-16 lh-normal">
                <li className="nav-item active">
                  <Link to="demo-elearning.html" className="nav-link ps-0">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="demo-elearning-about.html" className="nav-link">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="demo-elearning-courses.html" className="nav-link">
                    Courses
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                   to="demo-elearning-instructors.html"
                    className="nav-link"
                  >
                    Instructors
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                   to="demo-elearning-testimonial.html"
                    className="nav-link"
                  >
                    Testimonial
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="demo-elearning-blog.html" className="nav-link">
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="demo-elearning-contact.html" className="nav-link">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-xl-5 last-paragraph-no-margin text-center text-xl-end">
              <p className="fs-16">
                &copy; 2024 Website is Proudly Powered by{" "}
                <Link
                 to="#"
                  target="_blank"
                  className="text-white text-decoration-line-bottom"
                >
                  Seokont
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
