import React from "react";

import "bootstrap-icons/font/bootstrap-icons.css";
import feather from "feather-icons";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Scroll from "../Components/Scroll";
import SectionZero from "../Components/Donations/SectionZero";
import SectionOne from "../Components/Donations/SectionOne";

const AllDonation = () => {
  return (
    <>
      <Header />
      <SectionZero />
      <SectionOne />
      <Footer />
      <Scroll />
    </>
  );
};

export default AllDonation;
