import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link as ScrollLink } from "react-scroll";

const SectionOne = () => {
  // const scrollToSection = (id, duration = 1000) => {
  //   const section = document.getElementById(id);
  //   if (section) {
  //     const startPosition = window.scrollY;
  //     const targetPosition =
  //       section.getBoundingClientRect().top + window.scrollY;
  //     const distance = targetPosition - startPosition;
  //     let startTime = null;

  //     const animation = (currentTime) => {
  //       if (!startTime) startTime = currentTime;
  //       const timeElapsed = currentTime - startTime;
  //       const progress = Math.min(timeElapsed / duration, 1);
  //       const ease = easeInOutCubic(progress);

  //       window.scrollTo(0, startPosition + distance * ease);

  //       if (progress < 1) {
  //         requestAnimationFrame(animation);
  //       }
  //     };

  //     requestAnimationFrame(animation);
  //   }
  // };

  // const easeInOutCubic = (t) => {
  //   return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  // };

  return (
    <>
      <section
        id="down-section"
        className="background-position-left-top background-no-repeat position-relative"
        style={{ backgroundImage: "url('images/demo-elearning-02.png')" }}
      >
        <div
          className="position-absolute right-0px top-30px"
          data-bottom-top="transform: translateY(80px)"
          data-top-bottom="transform: translateY(-80px)"
        >
          <img src="images/demo-elearning-04.png" alt="" />
        </div>
        <div className="container">
          <div
            className="text-center position-absolute top-minus-40px left-0px right-0px z-index-1 d-none d-md-inline-block"
            // data-anime='{ "opacity": [0,1], "duration": 600, "delay":100, "staggervalue": 300, "easing": "easeOutQuad" }'
          >
            <div
              // onClick={() => scrollToSection("down-section", 1000)}
              className="d-block section-link"
            >
              <nav>
                <ScrollLink to="down-section" smooth={true} duration={1500}>
                  <div className="d-flex justify-content-center align-items-center mx-auto box-shadow-medium-bottom rounded-circle h-70px w-70px text-dark-gray bg-white">
                    <i className="bi bi-mouse icon-very-medium lh-0px"></i>
                  </div>
                </ScrollLink>
              </nav>
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              className="col-lg-5 col-md-10 position-relative md-mb-40px sm-mb-25px"
              // data-anime='{ "opacity": [0,1], "duration": 600, "delay":100, "staggervalue": 300, "easing": "easeOutQuad" }'
            >
              <div className="icon-with-text-style-08 mb-20px">
                <div className="feature-box feature-box-left-icon-middle">
                  <div className="feature-box-icon feature-box-icon-rounded w-55px h-55px rounded-circle bg-yellow me-15px">
                    <i className="bi bi-award d-inline-block icon-extra-medium text-dark-gray"></i>
                  </div>
                  <div className="feature-box-content last-paragraph-no-margin">
                    <span className="d-inline-block alt-font fs-19 fw-500 ls-minus-05px text-dark-gray">
                    Transparent, Secure, and Impactful Giving
                    </span>
                  </div>
                </div>
              </div>
              <h2 className="alt-font text-dark-gray fw-600 ls-minus-3px mx-auto">
              Why Choose Us for Crypto Donations?
              </h2>
              <a
                href="demo-elearning-about.html"
                className="btn btn-large btn-dark-gray btn-box-shadow btn-rounded btn-switch-text"
              >
                <span>
                  <span className="btn-double-text" data-text="Learn more">
                  Start Donating
                  </span>
                  <span>
                    {/* <i className="fa-solid fa-arrow-right"></i> */}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="fa-arrow-right"
                    />
                  </span>
                </span>
              </a>
              <div className="d-flex align-items-center fw-500 text-dark-gray w-100 position-absolute md-position-relative bottom-0 left-minus-5px md-mt-15px">
                <img src="https://via.placeholder.com/156x113" alt="" />
                <span className="d-inline-block position-relative lh-24">
                Make a Difference Today{" "}
                  <a
                    href="demo-elearning-instructors.html"
                    className="fw-600 text-decoration-line-bottom text-dark-gray "
                  >
                    experts.
                  </a>
                </span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-10 offset-xl-1 position-relative mt-minus-1">
              <div
                className="row row-cols-auto row-cols-sm-2"
                // data-anime='{ "el": "childs", "translateX": [30, 0], "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay":100, "staggervalue": 300, "easing": "easeOutQuad" }'
              >
                <div className="col align-self-start">
                  <div className="feature-box text-start ps-30px pe-30px xl-pe-5px sm-pe-0">
                    <div className="feature-box-icon position-absolute left-0px top-0px">
                      <h2 className="alt-font fs-100 fw-700 ls-minus-1px opacity-1 mb-0">
                        01
                      </h2>
                    </div>
                    <div className="feature-box-content last-paragraph-no-margin pt-30 md-pt-21 sm-pt-40px">
                      <span className="text-dark-gray fs-20 d-inline-block fw-600 mb-5px">
                      Seamless Transactions
                      </span>
                      <p>
                      Donations are processed instantly and transparently through blockchain, ensuring high speed and minimal fees.
                      </p>
                      <span className="w-60px h-3px bg-yellow d-inline-block"></span>
                    </div>
                  </div>
                </div>

                <div className="col align-self-end mt-25 xs-mt-30px">
                  <div className="feature-box text-start ps-30px pe-30px xl-pe-5px sm-pe-0">
                    <div className="feature-box-icon position-absolute left-0px top-0px">
                      <h2 className="alt-font fs-100 fw-700 ls-minus-1px opacity-1 mb-0">
                        02
                      </h2>
                    </div>
                    <div className="feature-box-content last-paragraph-no-margin pt-30 md-pt-21 sm-pt-40px">
                      <span className="text-dark-gray fs-20 d-inline-block fw-600 mb-5px">
                      Full Transparency
                      </span>
                      <p>
                      Every donor can track the movement of funds and their usage in real time.
                      </p>
                      <span className="w-60px h-3px bg-yellow d-inline-block"></span>
                    </div>
                  </div>
                </div>

                <div className="col align-self-start mt-minus-12 xs-mt-30px">
                  <div className="feature-box text-start ps-30px pe-30px xl-pe-5px sm-pe-0">
                    <div className="feature-box-icon position-absolute left-0px top-0px">
                      <h2 className="alt-font fs-100 fw-700 ls-minus-1px opacity-1 mb-0">
                        03
                      </h2>
                    </div>
                    <div className="feature-box-content last-paragraph-no-margin pt-30 md-pt-21 sm-pt-40px">
                      <span className="text-dark-gray fs-20 d-inline-block fw-600 mb-5px">
                      Global Reach
                      </span>
                      <p>
                      Support projects and initiatives worldwide without borders or limitations.
                      </p>
                      <span className="w-60px h-3px bg-yellow d-inline-block"></span>
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionOne;
