import { makeAutoObservable } from "mobx";

class AuthStore {
  user = {};
  isAuthenticated = false;

  constructor() {
    makeAutoObservable(this);
  }

  setUser(data) {
    this.user = data;
  }
  setIsAuthenticated(data) {
    this.isAuthenticated = data;
  }
}

const authStore = new AuthStore();
export default authStore;
