import React, { useEffect, useState, useRef } from "react";
import { FeatherIcon } from "../utils/featherIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";
import headerStore from "../store/HeaderStore";
import appStore from "../store/AppStore";
import authStore from "../store/AuthStore";
import { Routes, Route, Link, useNavigate } from "react-router-dom";

const Header = observer(() => {
  const buttonRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (appStore.scrollDistance > 100) {
      headerStore.setClassSticky("sticky");
    }
    if (appStore.scrollDistance < 100) {
      headerStore.setClassSticky("");
    }
    if (appStore.scrollDistance > 1000) {
      headerStore.setClassSticky("sticky sticky-active");
    }
  }, [appStore.scrollDistance]);

  const openMenuCoursesTrue = () => {
    headerStore.setOpenMenu(true);
  };
  const openMenuCoursesFalse = () => {
    headerStore.setOpenMenu(false);
  };

  const openToogle = () => {
    headerStore.setOpenBurger(!headerStore.openBurger);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    authStore.setIsAuthenticated(false);
    navigate("/");
  };
  return (
    <header className={headerStore.classSticky}>
      <nav
        className="navbar navbar-expand-lg header-transparent bg-transparent header-reverse"
        data-header-hover="light"
      >
        <div className="container-fluid">
          <div className="col-auto col-lg-2 me-lg-0 me-auto">
            <Link className="navbar-brand" to="/">
              <img
                src="images/demo-elearning-logo-white.png"
                data-at2x="images/demo-elearning-logo-white@2x.png"
                alt=""
                className="default-logo"
              />
              <img
                src="images/demo-elearning-logo-black.png"
                data-at2x="images/demo-elearning-logo-black@2x.png"
                alt=""
                className="alt-logo"
              />
              <img
                src="images/demo-elearning-logo-black.png"
                data-at2x="images/demo-elearning-logo-black@2x.png"
                alt=""
                className="mobile-logo"
              />
            </Link>
          </div>
          <div className="col-auto menu-order position-static">
            <button
              onClick={openToogle}
              className={`navbar-toggler float-start ${
                headerStore.openBurger ? "collapsed" : ""
              }`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
            </button>
            <div
              className={`${
                headerStore.openBurger ? "collapseing" : "collapse"
              } navbar-collapse`}
              id="navbarNav"
              ref={buttonRef}
            >
              <ul className="navbar-nav alt-font">
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                {authStore.isAuthenticated ? (
                  <li className="nav-item">
                    <Link to="/create-project" className="nav-link">
                      Create Project
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link to="/login" className="nav-link">
                      Create Project
                    </Link>
                  </li>
                )}

                <li className="nav-item">
                  <Link to="/all-donation" className="nav-link">
                    All Donation
                  </Link>
                </li>
                {/* <li
                  className={`nav-item dropdown dropdown-with-icon ${
                    headerStore.openMenu ? "open" : ""
                  }`}
                  onMouseEnter={openMenuCoursesTrue}
                  onMouseLeave={openMenuCoursesFalse}
                >
                  <a href="demo-elearning-courses.html" className="nav-link">
                    Courses
                  </a>
                  {/* <i
                    className="fa-solid fa-angle-down dropdown-toggle"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></i> */}
                {/* <FontAwesomeIcon
                    icon={faAngleDown}
                    className="fa-angle-down dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  />
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a href="demo-elearning-courses-details.html">
                        <i className="bi bi-laptop"></i>
                        <div className="submenu-icon-content">
                          <span>Development</span>
                          <p>Develop professional skills</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="demo-elearning-courses-details.html">
                        <i className="bi bi-briefcase"></i>
                        <div className="submenu-icon-content">
                          <span>Business</span>
                          <p>Advance your business</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="demo-elearning-courses-details.html">
                        <i className="bi bi-vector-pen"></i>
                        <div className="submenu-icon-content">
                          <span>Design</span>
                          <p>Design skills & concepts</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="demo-elearning-courses-details.html">
                        <i className="bi bi-megaphone"></i>
                        <div className="submenu-icon-content">
                          <span>Marketing</span>
                          <p>New age marketing skills</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li> */}
                {/* <li className="nav-item">
                  <a
                    href="demo-elearning-instructors.html"
                    className="nav-link"
                  >
                    Instructors
                  </a>
                </li> */}
                {/* <li className="nav-item">
                  <a
                    href="demo-elearning-testimonial.html"
                    className="nav-link"
                  >
                    Testimonial
                  </a>
                </li>
                <li className="nav-item">
                  <a href="demo-elearning-blog.html" className="nav-link">
                    Blog
                  </a>
                </li> */}
                <li className="nav-item">
                  <Link to="/contact" className="nav-link">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-auto col-lg-2 text-end d-none d-sm-flex">
            <div className="header-icon">
              <div>
                <div className="alt-font fw-500 lg-fs-15">
                  {authStore.isAuthenticated ? (
                    <div
                      onClick={handleLogout}
                      to="/"
                      className="widget-text text-white-hover"
                      style={{
                        display: "flex",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      Hi, {authStore.user.email}
                      <FeatherIcon
                        name="log-out"
                        size="24"
                        color={
                          appStore.scrollDistance < 1000 ? `white` : `#313e3b`
                        }
                        className="me-10px lg-me-5px"
                      />
                      {/* <i className="feather icon-feather-phone-call me-10px lg-me-5px"></i> */}
                      {/* logout */}
                    </div>
                  ) : (
                    <Link
                      to="/login"
                      className="widget-text text-white-hover"
                      style={{
                        display: "flex",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FeatherIcon
                        name="log-in"
                        size="24"
                        color={
                          appStore.scrollDistance < 1000 ? `white` : `#313e3b`
                        }
                        className="me-10px lg-me-5px"
                      />
                      {/* <i className="feather icon-feather-phone-call me-10px lg-me-5px"></i> */}
                      Login/Register
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
});

export default Header;
